import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import SingleSign from './content/SingleSign/SingleSign';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

const Login = Loader(lazy(() => import('src/content/pages/Authentication/Login/Login')));

// Dashboards

const Dashboard = Loader(lazy(() => import('src/content/dashboard/Dashboard')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/Messenger'))
);
const Accounts = Loader(
  lazy(() => import('src/content/Accounts'))
);
const AccountReview = Loader(
  lazy(() => import('src/content/Accounts/Review'))
)
const AccountProblem = Loader(
  lazy(() => import('src/content/Accounts/Problems'))
)
const Signs = Loader(
  lazy(() => import('src/content/Signs'))
);
const Orders = Loader(
  lazy(() => import('src/content/Orders'))
);
const Devices = Loader(
  lazy(() => import('src/content/Devices'))
)
const Setting = Loader(
  lazy(() => import('src/content/Setting'))
)
const Inquery = Loader(
  lazy(() => import('src/content/Inquery'))
);
const Products = Loader(
  lazy(() => import('src/content/Product'))
)
const SignRegions = Loader(
  lazy(() => import('src/content/SignRegions'))
)
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);


const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="login" replace />
      },
      {
        path: 'T-Company',
        element: <SingleSign companyName='TCompany' />
      },
      {
        path: 'A-Company',
        element: <SingleSign companyName='ACompany' />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: '',
        element: <Navigate to="test" replace />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*' || 'not-found',
        element: <Status404 />
      }
    ]
  },
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="accounts" replace />
      },
      {
        path: '',
        element: <Navigate to="accountReview" replace />
      },
      {
        path: '',
        element: <Navigate to="accountProblem" replace />
      },
      {
        path: '',
        element: <Navigate to="signs" replace />
      },
      {
        path: '',
        element: <Navigate to="orders" replace />
      },
      {
        path: '',
        element: <Navigate to="devices" replace />
      },
      {
        path: '',
        element: <Navigate to="setting" replace />
      },
      {
        path: '',
        element: <Navigate to="inquiry" replace />
      },
      {
        path: '',
        element: <Navigate to="products" replace />
      },
      {
        path: 'accounts',
        element: <Accounts />
      },
      {
        path: 'accountReview',
        element: <AccountReview />
      },
      {
        path: 'accountProblem',
        element: <AccountProblem />
      },
      {
        path: 'signs',
        element: <Signs />
      },
      {
        path: 'orders',
        element: <Orders />
      },
      {
        path: 'devices',
        element: <Devices />
      },
      {
        path: 'setting',
        element: <Setting />
      },
      {
        path: 'inquery',
        element: <Inquery />
      },
      {
        path: 'products',
        element: <Products />
      },
      {
        path: 'signRegions',
        element: <SignRegions />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
