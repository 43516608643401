import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import axios from 'axios';
import './App.css';
import { returnBaseUrl } from './constants/constants';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  let token = localStorage.getItem('token');

  function axiosConfig() {
    axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (error) => {
        if(!error.response) {
          toast.error('Server connection problem , please check internet')
        }
        else if (
          error.response.status === 401 &&
          window.location.pathname !== '/login'
        ) {
          localStorage.removeItem('token');
          window.location.href = '/login';
        } else if (error.response.status === 404) {
          window.location.href = '/not-found';
        } else if (error.response.status === 403) {
          window.location.href = '/dashboards';
        } else if (error.response.status === 400) {
          toast.error(error.response.data || error.message)
        }
      }
    );

    if (token !== undefined && token !== null && token !== '')
      axios.defaults.headers.common['authorization'] = `Bearer ${token}`;

    axios.defaults.baseURL = returnBaseUrl();
  }

  axiosConfig();

  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <ToastContainer
          position="top-center"
        />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
