import { useContext, useState, useEffect } from 'react';
import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  AppBar,
  Typography,
  Toolbar,
  Badge
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuIcon from '@mui/icons-material/Menu';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';
import { Notifications } from '@mui/icons-material';
import NotificationService from 'src/services/NotificationService';
import NotifPopup from './NotifPopup';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);
function Header({ openDrawer }) {
  var notificationService = new NotificationService();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const [notifCount, setNotifCount] = useState(0);
  const [visible, setVisible] = useState(false);
  const theme = useTheme();
  const drawerWidth = 270;

  useEffect(() => {
    getNotifications();
  }, [60000]);

  const getNotifications = () => {
    notificationService.getCount().then((response) => {
      setNotifCount(response);
    });
  };

  return (
    <HeaderWrapper
      display="flex"
      flexDirection={'row-reverse'}
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` }
        }}
        color={'inherit'}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={openDrawer}
            className="menu"
            sx={{
              mt: 9,
              display: {
                xs: 'block',
                sm: 'none',
                position: 'absolute',
                left: 10
              }
            }}
          >
            <MenuIcon
              sx={{ padding: '2px', fontSize: '25px', color: 'pale_grey.dark' }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ }}>
        <IconButton onClick={() => setVisible(true)}>
          <Badge badgeContent={notifCount} color="error">
            <Notifications fontSize="large" color="action" />
          </Badge>
        </IconButton>
      </Box>
      {visible && (
        <NotifPopup
          onClose={() => {
            setVisible(false);
            getNotifications();
          }}
          onSeen={() => getNotifications()}
        />
      )}

      {/* <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <HeaderMenu />
      </Stack> */}

      {/* <Box display="flex" alignItems="center">
        <HeaderButtons />
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box> */}
    </HeaderWrapper>
  );
}
export default Header;
