const DEV_API_URL = 'https://api.easyinstall.store';
const PROD_API_URL = 'https://api.sign98.online';
const STAGE_API_URL = 'https://api-staging.sign98.online';

export const returnBaseUrl = () => {
  const url = window.location.origin;

  if (process.env.NODE_ENV === 'development') return DEV_API_URL;
  else if (url == 'https://panel.sign98.online') return PROD_API_URL;
  else if (url == 'https://staging.sign98.online') return STAGE_API_URL;
  else if (url == 'https://test.easyinstall.store') return 'https://api.easyinstall.store';
};

const DEV_API_AUTHENTICATION_URL = 'https://auth.easyinstall.store';
const PROD_API_AUTHENTICATION_URL = 'https://auth.sign98.online';
const STAGE_API_AUTHENTICATION_URL = 'https://auth-staging.sign98.online';

export const returnAuthenticationUrl = () => {
  const url = window.location.origin;

  if (process.env.NODE_ENV === 'development') return DEV_API_AUTHENTICATION_URL;
  else if (url == 'https://panel.sign98.online') return PROD_API_AUTHENTICATION_URL;
  else if (url == 'https://staging.sign98.online') return STAGE_API_AUTHENTICATION_URL;
  else if (url == 'https://panel.easyinstall.store') return 'https://auth.easyinstall.store';
};