import axios from 'axios';
import { mapToDataOnly } from './base/HttpUtility';
import { returnAuthenticationUrl } from 'src/constants/constants';

export interface LoginDto {
  username: string;
  password: string;
}

export default class AuthenticationService {
  Login(Dto: LoginDto): Promise<{accessToken:string}> {
    return mapToDataOnly<any>(axios.post(`${returnAuthenticationUrl()}/SignIn`, Dto));
  }
}
