import React from 'react';

import {
  Typography,
  Grid,
  IconButton,
  Popover,
  Box,
  CircularProgress
} from '@mui/material';
import NotificationService, {
  NotificationDto
} from 'src/services/NotificationService';
import DateFormat from 'src/components/utilities/DateFormat';
import { Close } from '@mui/icons-material';

export default class NotifPopup extends React.PureComponent<
  {
    onClose: () => void;
    onSeen: () => any;
  },
  {
    notifications: NotificationDto[];
    loading: boolean;
  }
> {
  NotificationService: NotificationService;

  constructor(props: any) {
    super(props);
    this.state = { notifications: [], loading: false };
    this.NotificationService = new NotificationService();
  }

  componentDidMount(): void {
    this.setState({loading: true});
    this.NotificationService.get().then((response) => {
      this.setState({ notifications: response, loading: false });
    });
  }

  renderNotifBox = (
    id: string,
    title: string,
    description: string,
    createAt: string,
    lastItem: boolean
  ) => {
    return (
      <Box
        sx={{
          p: 1,
          width: '100%',
          borderBottom: lastItem ? 'none' : '1px solid #EFF1F3'
        }}
        key={id}
      >
        <Box display={'flex'} justifyContent={'end'}>
          <IconButton
            sx={{ padding: 0.2, mb: 1 }}
            onClick={() => this.seen(id)}
          >
            <Close fontSize="small" color="error" />
          </IconButton>
        </Box>
        <Box pr={2} pl={2}>
          <Grid display={'flex'} justifyContent={'space-between'}>
            <Typography variant="h5">{title}</Typography>
            <Typography color={'gray'} fontSize={12}>
              {DateFormat(createAt)}
            </Typography>
          </Grid>
          <Typography sx={{ mt: 1 }}>{description}</Typography>
        </Box>
      </Box>
    );
  };

  seen = (id: string) => {
    let { notifications } = this.state;
    let data = notifications;
    this.NotificationService.seen(id).then(() => {
      let index = notifications.map(({ id }) => id).indexOf(id);
      data.splice(index, 1);
      this.setState({ notifications: data });
      this.props.onSeen();
      this.forceUpdate();
    });
  };

  render(): React.ReactNode {
    let { onClose } = this.props;
    let { notifications, loading } = this.state;

    return (
      <Popover
        open={true}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ top: 50, backdropFilter: 'blur(0px)' }}
      >
        <Box
          sx={{
            width: 500,
            minHeight: 100,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : notifications?.length == 0 ? (
            <Typography>You don't have any notification!</Typography>
          ) : (
            notifications?.map((notif, index) => {
              return this.renderNotifBox(
                notif.id,
                notif.title,
                notif.description,
                notif.createAt,
                notifications.length == index + 1
              );
            })
          )}
        </Box>
      </Popover>
    );
  }
}
