import axios from 'axios';
import { mapToDataOnly } from './base/HttpUtility';

export interface NotificationDto {
  id: string;
  title: string;
  description: string;
  isReview: boolean;
  createAt: string;
}

export default class NotificationService {
  get(): Promise<NotificationDto[]> {
    return mapToDataOnly<NotificationDto[]>(axios.get('/Notification'));
  }

  getCount(): Promise<number> {
    return mapToDataOnly<number>(axios.get('/Notification/NotificationCount'));
  }

  seen(id: string): Promise<NotificationDto> {
    return mapToDataOnly<NotificationDto>(
      axios.put(`/Notification/Seen?id=${id}`)
    );
  }
}
