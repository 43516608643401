import {
    Grid,
    Container,
    Box,
    Typography,
    TextField,
    Button,
    Card,
    CircularProgress,
} from '@mui/material';

import React from 'react';
import { IProps, IState } from './SingleSign.models';
import AuthenticationService from 'src/services/AuthenticationService';
import { toast } from 'react-toastify';
import axios from 'axios';
import { returnAuthenticationUrl, returnBaseUrl } from 'src/constants/constants';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { styled } from '@mui/material/styles';
import Spinner from 'react-bootstrap/esm/Spinner';

export default class SingleSign extends React.PureComponent<IProps, IState> {
    AuthenticationService: AuthenticationService;

    constructor(props: any) {
        super(props);
        this.state = {
            registered: false,
            ipaUrl: '',
            requestIpaUrl: '',
            loading: false,
            progress: 0,
            progress2: 0,
            showApplications: false,
            remainingCount: 0
        };
        this.AuthenticationService = new AuthenticationService();
    }

    componentDidMount(): void {
        const queryParams = new URLSearchParams(window.location.search)
        var udid = queryParams.get("udid")
        if (!udid && localStorage.getItem('udid') !== null)
            udid = localStorage.getItem('udid');
        else if (udid && localStorage.getItem('udid') === null)
            localStorage.setItem('udid', udid)

        this.getRemainingCount();

        this.setState({ udid })

        setInterval(() => {
            if (this.state.progress < 99)
                this.setState({ progress: this.state.progress + 1 })
        }, 400)

        setInterval(() => {
            if (this.state.progress2 < 100)
                this.setState({ progress2: this.state.progress2 + 1 })
        }, 30)

        if (udid)
            setInterval(() => {
                this.refreshStatus();
            }, 1000)
    }

    refreshStatus = () => {
        if (this.state.registered === true)
            return;
        axios.post(`${returnBaseUrl()}/Test/Refresh`, {
            udid: this.state.udid,
        }).then((response) => {
            if (response.data.registered === true) {
                this.setState({ registered: true });
                this.addTestCount();
            }
        });
    }

    addTestCount = () => {
        axios.post(`${returnBaseUrl()}/Test/AddTestCount`, {
            udid: this.state.udid,
            companyName: this.props.companyName
        }).then(() => {
            this.getRemainingCount();
        });
    }

    getRemainingCount = () => {
        axios.get(`${returnBaseUrl()}/Test/RemainingCount/${this.props.companyName}`).then((response) => {
            this.setState({ remainingCount: response.data.count });
        });
    }

    install = (udid: string) => {
        this.setState({ ipaUrl: '' })
        var urls = ['https://Easyinstall.store/DocStorage.ipa', 'https://Easyinstall.store/AirShou.ipa', 'https://Easyinstall.store/tumblr.ipa'];
        var urls2 = ['https://Easyinstall.store/twitter-plus.ipa', 'https://Easyinstall.store/instagramRhino.ipa', 'https://Easyinstall.store/reddit-tweak.ipa'];
        this.setState({ loading: true, progress: 0 })
        axios.put(`${returnBaseUrl()}/Test`, {
            udid,
            ipaUrl: this.props.companyName == 'TCompany' ? urls[this.state.selectedAppNumber] : urls2[this.state.selectedAppNumber]

        }).then((response) => {
            if (response.data.ipaUrl) {
                this.setState({ ipaUrl: response.data.ipaUrl, progress: 100, loading: false });
                document.getElementById("install_click").click();
            }
        });

        setInterval(() => {
            if (!this.state.ipaUrl) {
                axios.put(`${returnBaseUrl()}/Test`, {
                    udid,
                    ipaUrl: this.props.companyName == 'TCompany' ? urls[this.state.selectedAppNumber] : urls2[this.state.selectedAppNumber]
                }).then((response) => {
                    if (response.data.ipaUrl) {
                        this.setState({ ipaUrl: response.data.ipaUrl, progress: 100, loading: false });
                        document.getElementById("install_click").click();
                    }
                });
            }

        }, 3000)



    };

    render(): React.ReactNode {
        let { loading } = this.state;



        return (
            <Container sx={{ display: 'flex', flex: 1, height: '100vh' }}>
                <Grid container justifyContent="center" alignItems={'center'}>
                    <Card sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
                        {this.props.companyName == 'TCompany' ? <Typography variant="h3" textAlign={'center'} style={{ color: '#ede31c' }}>T-Company</Typography>
                            : <Typography variant="h3" textAlign={'center'} style={{ color: 'red' }}>A-Company</Typography>}
                        <Typography variant="h4" textAlign={'center'} style={{ color: this.props.companyName == 'TCompany' ? '#ede31c' : 'red' }}>Test Device Count: {this.state.remainingCount}/2</Typography>
                        {this.state.udid !== undefined && this.state.udid !== null ? <Button
                            sx={{ mt: 2 }}
                            variant="contained"
                            color='info'
                            disabled={true}
                        >
                            1. Get Device Info
                        </Button> : <a href={this.props.companyName == 'TCompany' ? 'https://test.easyinstall.store/device.mobileconfig' : 'https://test.easyinstall.store/device2.mobileconfig'} style={{ textDecoration: 'none' }}>
                            <Button
                                sx={{ mt: 2 }}
                                variant="contained"
                                color='info'
                            >
                                1. Get Device Info
                            </Button>
                        </a>}


                        {
                            !this.state.registered && this.state.udid && <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <CircularProgress color="primary" size={50} />
                            </div>
                        }

                        <a href={this.state.ipaUrl} style={{ textDecoration: 'none' }} id="install_click"> </a>

                        <Button
                            sx={{ mt: 2 }}
                            variant="contained"
                            color='info'
                            disabled={!this.state.udid || this.state.loading || !this.state.registered}
                            onClick={() => this.setState({ showApplications: true })}
                        >
                            2. Select a application
                        </Button>
                        {
                            this.state.showApplications && (
                                this.props.companyName == 'ACompany' ?
                                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                        <div onClick={() => this.setState({ selectedAppNumber: 0 })}
                                            style={{
                                                display: 'inline-block', background: this.state.selectedAppNumber === 0 && 'color(srgb 0.825 0.9477 1)',
                                                padding: '5px',
                                                borderRadius: '15px'
                                            }}>
                                            <img src="/twitter.png" width={60} height={60} style={{ borderRadius: '15px' }} />
                                            <br />
                                            <span style={{ fontSize: '11px' }}>Twitter</span>
                                        </div>
                                        <div
                                            onClick={() => this.setState({ selectedAppNumber: 1 })}
                                            style={{
                                                // opacity: '0.5',
                                                display: 'inline-block', background: this.state.selectedAppNumber === 1 && 'color(srgb 0.825 0.9477 1)',
                                                padding: '5px',
                                                borderRadius: '15px'
                                            }}>
                                            <img src="/insta.png" width={60} height={60} style={{ borderRadius: '15px' }} />
                                            <br />
                                            instagram ++
                                        </div>
                                        <div
                                            onClick={() => this.setState({ selectedAppNumber: 2 })}
                                            style={{
                                                // opacity: '0.5',
                                                display: 'inline-block', background: this.state.selectedAppNumber === 2 && 'color(srgb 0.825 0.9477 1)',
                                                padding: '5px',
                                                borderRadius: '15px'
                                            }}>
                                            <img src="/reddit.png" width={60} height={60} style={{ borderRadius: '15px' }} />
                                            <br />
                                            Reddit ++
                                        </div>
                                    </div> : <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                        <div onClick={() => this.setState({ selectedAppNumber: 0 })}
                                            style={{
                                                display: 'inline-block', background: this.state.selectedAppNumber === 0 && 'color(srgb 0.825 0.9477 1)',
                                                padding: '10px',
                                                borderRadius: '15px'
                                            }}>
                                            <img src="/docs-app.png" width={60} height={60} style={{ borderRadius: '15px' }} />
                                            <br />
                                            <span style={{ fontSize: '11px' }}>DocStorage</span>
                                        </div>
                                        <div
                                            onClick={() => this.setState({ selectedAppNumber: 1 })}
                                            style={{
                                                // opacity: '0.5',
                                                display: 'inline-block', background: this.state.selectedAppNumber === 1 && 'color(srgb 0.825 0.9477 1)',
                                                padding: '10px',
                                                borderRadius: '15px'
                                            }}>
                                            <img src="/airsh.png" width={60} height={60} style={{ borderRadius: '15px' }} />
                                            <br />
                                            AirShou
                                        </div>
                                        <div
                                            onClick={() => this.setState({ selectedAppNumber: 2 })}
                                            style={{
                                                // opacity: '0.5',
                                                display: 'inline-block', background: this.state.selectedAppNumber === 2 && 'color(srgb 0.825 0.9477 1)',
                                                padding: '10px',
                                                borderRadius: '15px'
                                            }}>
                                            <img src="/tumb.png" width={60} height={60} style={{ borderRadius: '15px' }} />
                                            <br />
                                            Tumblr++
                                        </div>
                                    </div>
                            )
                        }


                        <Button
                            sx={{ mt: 2 }}
                            variant="contained"
                            color='info'
                            disabled={this.state.selectedAppNumber === undefined || this.state.selectedAppNumber === null || this.state.loading}
                            style={{ marginTop: '10px' }}
                            onClick={() => this.install(this.state.udid)}
                        >
                            3. Install Application
                        </Button>

                        {
                            this.state.loading && <div style={{ textAlign: 'center', fontSize: '20px', marginTop: '10px', color: 'rgb(40, 155, 204)', fontWeight: 'bold' }}>
                                {this.state.progress}%
                            </div>
                        }

                        {/* <BorderLinearProgress variant="determinate" value={50} /> */}
                    </Card>
                </Grid>
            </Container>
        );
    }
}
